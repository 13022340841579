<template>
    <div class="editUser" :style="editUser">
        <common-nav-bar title="认证信息"></common-nav-bar>
        <van-cell-group class="bgNo">
            <van-cell center title-class="userLeft" title="头像">
                <template #right-icon>
                    <van-image round class="userPhoto" :src="user.wxHeadimgurl" />
                </template>
            </van-cell>
            <van-field
                v-model="name"
                label="姓名"
                placeholder="请输入真实姓名"
                input-align="right"
            />
            <van-cell
                center
                title-class="userLeft"
                value-class="userRight"
                is-link
                title="性别"
                @click="showSexPop = true"
                :value="sex"
            />
            <van-cell
                center
                title-class="userLeft"
                value-class="userRight"
                is-link
                title="出生日期"
                :value="birthday"
                @click="showBirthdayPop = true"
            />
            <van-field
                v-model="education"
                label="学历"
                placeholder="请输入学历"
                input-align="right"
            />
            <van-field
                v-model="professionalTitle"
                label="职称"
                placeholder="请输入职称"
                input-align="right"
            />
            <van-field
                v-model="goodAt"
                label="擅长领域"
                placeholder="请输入擅长领域"
                input-align="right"
            />
            <van-field
                v-model="company"
                label="工作单位"
                placeholder="请输入工作单位"
                input-align="right"
            />
            <van-cell center title="职业注册证">
                <template #extra>
                    <van-uploader
                        :file-list="certImgs"
                        :after-read="uploadFiles"
                        :max-count="1"
                        :before-delete="deleteCertImg"
                    />
                </template>
            </van-cell>
            <van-cell class="textC bgNo" size="lager">
                <template #title>
                    <van-button
                        v-if="!certification.auditStatus || certification.auditStatus === 2"
                        class="newBook plr40 mt30"
                        color="#4AB8AB"
                        size="normal"
                        @click="certificate"
                        >提交认证
                    </van-button>
                    <van-button
                        v-if="certification.auditStatus === 1"
                        class="newBook plr40 mt30"
                        color="#D5D5D5"
                        size="normal"
                        >认证审核中
                    </van-button>
                    <van-button
                        v-if="certification.auditStatus === 3"
                        class="newBook plr40 mt30"
                        color="#D5D5D5"
                        size="normal"
                        >认证通过
                    </van-button>
                </template>
            </van-cell>
        </van-cell-group>
        <van-popup v-model="showSexPop" position="bottom">
            <van-picker
                show-toolbar
                :columns="sexColumns"
                @confirm="pickSex"
                @cancel="showSexPop = false"
            />
        </van-popup>
        <van-popup v-model="showBirthdayPop" position="bottom">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="pickBirthDate"
                @cancel="showBirthdayPop = false"
            />
        </van-popup>
    </div>
</template>

<script>
import { getUser } from '@/services/user';
import { toast,formatDate } from '@/util';
import { certificate, getCertification } from '@/services/doctor';
import { uploadFiles } from '@/services/upload';
import { BaseHost } from '@/services/base';
import CommonNavBar from '@/components/common-nav-bar';

export default {
    components: { CommonNavBar },
    // name: "index"
    data() {
        return {
            editUser: {
                background: '#F6F6F8',
                height: '100%',
            },
            showSexPop: false,
            sexColumns: ['男', '女'],
            showBirthdayPop: false,
            minDate: new Date('1950-01-01'),
            maxDate: new Date(),
            currentDate: new Date(),

            name: '',
            sex: '',
            birthday: '',
            education: '',
            professionalTitle: '',
            goodAt: '',
            company: '',
            certImgs: [],

            user: {},
            certification: {},
        };
    },
    created() {
        this.getUser();
        this.getCertification();
    },
    methods: {
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            this.user = data;
            this.initUser();
        },

        async getCertification() {
            const { code, message, data } = await getCertification();
            if (!code) {
                return toast(message);
            }
            if (!data) {
                return;
            }
            this.certification = data;
            this.initCertification();
        },

        initUser() {
            this.name = this.user.name;
            this.sex = this.user.sex === 1 ? '男' : this.user.sex === 2 ? '女' : '';
            this.birthday = this.user.birthday
                ? formatDate(this.user.birthday, 'yyyy-MM-dd')
                : '';
        },

        initCertification() {
            this.education = this.certification.education;
            this.professionalTitle = this.certification.professionalTitle;
            this.goodAt = this.certification.goodAt;
            this.company = this.certification.company;
            if (this.certification.certImg) {
                this.certImgs = [{ url: BaseHost + '/' + this.certification.certImg }];
            }
        },

        pickSex(val) {
            this.sex = val;
            this.showSexPop = false;
        },

        pickBirthDate(val) {
            this.birthday = formatDate(val, 'yyyy-MM-dd');
            this.showBirthdayPop = false;
        },

        async uploadFiles(file) {
            const { code, message, data } = await uploadFiles([file.file]);
            if (!code) {
                toast(message);
            }
            if (!data.length) {
                toast('上传失败');
            }
            this.certImgs = [{ url: BaseHost + '/' + data[0].path }];
        },
        deleteCertImg() {
            this.certImgs = [];
        },

        async certificate() {
            const err = this.validate();
            if (err) {
                return toast(err);
            }
            const { code, message } = await certificate({
                name: this.name,
                sex: this.sex === '男' ? 1 : this.sex === '女' ? 2 : 0,
                birthday: this.birthday,
                education: this.education,
                professionalTitle: this.professionalTitle,
                goodAt: this.goodAt,
                company: this.company,
                certImg: this.certImgs.length
                    ? this.certImgs[0].url.substring(BaseHost.length + 1)
                    : '',
            });
            if (!code) {
                return toast(message);
            }
            toast('提交成功');
            await Promise.all([this.getUser(), this.getCertification()]);
        },

        validate() {
            if (!this.name) {
                return '请输入真实姓名';
            }
        },
    },
};
</script>

<style scoped>
.editUser .van-cell {
    margin-bottom: 5px;
}

.userPhoto {
    width: 35px;
    height: 35px;
}

.userLeft {
    font-size: 14px;
    color: #818181;
}

.userRight {
    font-size: 14px;
    color: #383838;
}

.newBook {
    height: 35px;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
}
</style>
