import axios from 'axios';
import { Url } from '@/services/base';

async function uploadFiles(files) {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token || '',
        'x-user-id': userId || '',
    };

    let param = new FormData();
    for (const file of files) {
        param.append('file', file);
    }
    try {
        const res = await axios.post(Url.upload.uploadFiles, param, { headers });
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export { uploadFiles };
